import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import MapContainer from "../../components/map"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
      query {
          company: directusCompany {
              legal_name
              email
              phone
              fax
              oib
          }
          company_address: directusCompanyAddress(language: {eq: "en"}) {
              address
          }
      }
  `)

  return (
    <Layout>
      <SEO title={"Contacts"} description={null} lang={"en"} url={"/contacts"} />
      <Container fluid>
        <Row className={"site-content align-items-center"}>
          <Col md={5} className={"offset-md-1 my-3"}>

            <h1>{ data.company.legal_name }</h1>
            { data.company_address.address.split('\n').map((line, key) => (
              <p className={"mb-1"} key={key}>{ line }</p>
            ))}

            <p className={"mt-4 mb-1"}>Phone</p>
            { data.company.phone.split('\n').map((phone, key) => (
              <p className={"mb-1"} key={key}><a href={`tel:${phone}`}><i className="fas fa-phone-alt"/><span className={"ml-2"}>{ phone }</span></a></p>
            ))}

            <p className={"mt-3 mb-1"}>Fax</p>
            { data.company.fax.split('\n').map((fax, key) => (
              <p className={"mb-1"} key={key}><a href={`tel:${fax}`}><i className="fas fa-fax"/><span className={"ml-2"}>{ fax }</span></a></p>
            ))}

            <p className={"mt-3 mb-1"}>Email</p>
            <p className={"mb-1"}><a href={`mailto:${data.company.email}`}><i className="fas fa-envelope"/><span className={"ml-2"}>{ data.company.email }</span></a></p>

          </Col>
          <Col xs={12} sm={12} md={4} className={"my-3"}>
            <Form name={"contacts"} netlify-honeypot="bot-field" data-netlify="true">
              <input type="hidden" name="bot-field" />
              <Form.Group>
                <Form.Control type="text" name="name" placeholder="Name and Surname" />
              </Form.Group>
              <Form.Group>
                <Form.Control type="email" name="email" placeholder="Mail" />
              </Form.Group>
              <Form.Group>
                <Form.Control type="numeric" name="phone" placeholder="Phone" />
              </Form.Group>
              <Form.Group>
                <Form.Control type="text" name="company" placeholder="Company" />
              </Form.Group>
              <Form.Group>
                <Form.Control as="textarea" name="message" rows="3" placeholder="Message" />
              </Form.Group>
              <Button className={"float-right"} variant="primary" type="submit">
                Submit
              </Button>
            </Form>
          </Col>
        </Row>
        <Row style={{ height: '480px' }}>
          <Col className={"p-0"}>
            <MapContainer></MapContainer>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default IndexPage
